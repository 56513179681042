import { webAPIUrl } from '../AppSettings';

export interface HttpRequest<REQB> {
  path: string;
  method?: string;
  body?: REQB;
  accessToken?: string;
}

export interface HttpResponse<RESB> {
  ok: boolean;
  body?: RESB;
}

export const Http = async <RESB, REQB = undefined>(
  config: HttpRequest<REQB>
): Promise<HttpResponse<RESB>> => {
  // Create Request
  const request = new Request(`${webAPIUrl}${config.path}`, {
    method: config.method || 'get',
    headers: { 'Content-Type': 'application/json' },
    body: config.body ? JSON.stringify(config.body) : undefined
  });
  // Add support for Access Token
  if (config.accessToken) {
    request.headers.set('authorization', `bearer ${config.accessToken}`);
  }

  // Make Request and retreive Response
  const response = await fetch(request);
  if (response.status === 200) {
    // Sucessful
    const body = await response.json();
    return { ok: response.ok, body };
  } else if (response.ok) {
    return { ok: response.ok };
  } else {
    // Failure
    logError(request, response);
    return { ok: response.ok };
  }
};

const logError = async (request: Request, response: Response) => {
  const contentType = response.headers.get('content-type');
  let body: any;
  if (contentType && contentType.indexOf('application/json') !== -1) {
    body = await response.json();
  } else {
    body = await response.text();
  }
  console.error(
    `Error requesting ${request.method}       ${request.url}`,
    body
  );
};
