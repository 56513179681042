import React from 'react';
import './App.css';
//Name: Animate.css
//install: $ npm install animate.css --save
// Website: https://animate.style
import 'animate.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/Homepage';
//Name: smoothscroll-polyfill
//install: $ npm install smoothscroll-polyfill --save
//Github: https://github.com/iamdustan/smoothscroll
import smoothscroll from 'smoothscroll-polyfill';
//import redux store
import { Provider } from 'react-redux';
import { configureStore } from './Store';
import { Footer } from './core/footer/Footer';
import { Admin } from './pages/Admin';

const store = configureStore();
function App() {
  // kick off the polyfill!
  smoothscroll.polyfill();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          {!process.env.REACT_APP_ENV && (
            <div style={{ color: 'red' }}>Development Mode</div>
          )}

          <Routes>
            <Route path="" element={<HomePage />} />
            <Route path="admin" element={<Admin />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
          <Footer></Footer>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
