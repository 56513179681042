import React from 'react';
import styles from './Banner.module.css';
import { NavHashLink } from 'react-router-hash-link';
import { Fade, Slide } from 'react-awesome-reveal';
//Use redux for Navigation App State
import { useDispatch } from 'react-redux';
import {
  changedSelectedNavigationAction,
  changingSelectedNavigationAction
} from '../Store';

interface Props {
  showButton?: boolean;
  children: React.ReactNode;
}

export const Banner = ({ showButton = true, children }: Props) => {
  const dispatch = useDispatch();

  const handleClick = (value: string) => () => {
    dispatch(changingSelectedNavigationAction());
    dispatch(changedSelectedNavigationAction(value));
  };

  const scrollWithOffset = (el: HTMLElement, offset: number) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={styles.question}>
      <Slide>
        <p>{children}</p>
      </Slide>
      <Fade delay={500} duration={2000} triggerOnce={true}>
        {showButton && (
          <NavHashLink
            smooth
            to="./#contact"
            className={styles.custombutton}
            scroll={(el) => scrollWithOffset(el, 80)}
            onClick={handleClick('contact')}
            replace={true}
          >
            Contact Us
          </NavHashLink>
        )}
      </Fade>
    </div>
  );
};
