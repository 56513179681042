import React from 'react';
// npm install react-aad-msal msal --save
import {
  AuthenticationState,
  AzureAD,
  IAzureADFunctionProps
} from 'react-aad-msal';
import { authProvider } from '../authProvider';

export const Admin = () => {
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {({
        login,
        logout,
        authenticationState,
        error,
        accountInfo
      }: IAzureADFunctionProps) => {
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            return (
              <p>
                <span>Welcome, {accountInfo?.account.userName}!</span>
                <button onClick={logout}>Logout</button>
              </p>
            );
          case AuthenticationState.Unauthenticated:
            return (
              <div>
                {error && (
                  <p>
                    <span>
                      An error occured during authentication, please try again!
                    </span>
                  </p>
                )}
                <p>
                  <span>Hey stranger, you look new!</span>
                  <button onClick={login}>Login</button>
                </p>
              </div>
            );
          case AuthenticationState.InProgress:
            return <p>Authenticating...</p>;
        }
      }}
    </AzureAD>
  );
};
