import React from 'react';
import { Banner } from '../banner/Banner';
import { PageSection } from '../shared/pagesection/PageSection';
import { OurServices } from '../ourservices/OurServices';
import { Contact } from '../contact/Contact';
import { Fade } from 'react-awesome-reveal';
import { About } from '../about/About';
import { Intro } from '../core/intro/Intro';
import { NavBar } from '../core/navBar/NavBar';

export const HomePage = () => (
  <>
    <Intro id="home" linkToElementId="about"></Intro>
    <NavBar />
    <div>
      <PageSection
        id="about"
        title="About us"
        caption="Who we are"
        inverse={true}
      >
        <About></About>
      </PageSection>

      <Banner>Want to find out how we can help you?</Banner>

      <PageSection id="services" title="Our Services" caption="What we do">
        <Fade delay={100} duration={1000} direction="right" triggerOnce={true}>
          <OurServices></OurServices>
        </Fade>
      </PageSection>

      <PageSection
        id="contact"
        title="Contact us"
        caption="Get in touch"
        inverse={true}
      >
        <Contact></Contact>
      </PageSection>
    </div>
  </>
);
