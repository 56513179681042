import { Http } from '../services/Http';
import { authProvider } from '../authProvider';

export interface ContactData {
  name: string;
  email: string;
  message: string;
}

interface ContactMessage {
  text: string;
}

// const wait = (ms: number): Promise<void> => {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// };

export const PostContact = async (contact: ContactData): Promise<boolean> => {
  // const token = await authProvider.getAccessToken();
  const accessToken = ''; //token.accessToken;
  const result = await Http<undefined, ContactData>({
    path: '/email',
    method: 'post',
    body: contact,
    accessToken
  });

  if (result.ok) {
    //&& result.body) {
    //console.log(result.body);
    return true;
  } else {
    return false;
  }
};

export const PingContact = async (): Promise<string> => {
  const result = await Http<ContactMessage>({ path: '/email' });
  if (result.ok && result.body) {
    console.log(result.body);
    return `Service successfully pinged ${result.body && result.body.text}`;
  } else {
    return '';
  }
};
