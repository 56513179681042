import { authSettings } from './AppSettings';
// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
  auth: {
    authority: authSettings.authority,
    clientId: authSettings.client_id,
    postLogoutRedirectUri: authSettings.logoutRedirectUri,
    redirectUri: authSettings.redirect_uri,
    validateAuthority: true,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [`${authSettings.client_id}/.default`]
};

export const authenticationParametersGraph = {
  scopes: ['openid']
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
