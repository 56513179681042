import React from 'react';
import styles from './Intro.module.css';
import { NavHashLink } from 'react-router-hash-link';
import { useDispatch } from 'react-redux';
import {
  changedSelectedNavigationAction,
  changingSelectedNavigationAction
} from '../../Store';

interface Props {
  id: string;
  linkToElementId: string;
}

export const Intro = ({ id, linkToElementId }: Props) => {
  const dispatch = useDispatch();

  const scrollWithOffset = (el: HTMLElement, offset: number) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleClick = (value: string) => () => {
    dispatch(changingSelectedNavigationAction());
    dispatch(changedSelectedNavigationAction(value));
  };

  return (
    <div
      id={id}
      className={`${styles.introContainer} animate__animated animate__backInLeft`}
    >
      <ul className={styles.introContent}>
        <li className={styles.actionText}>
          <span className={styles.mainText}>Resonate</span>
          <span>IT</span>
        </li>
        <li className={styles.caption}>
          <span>Providing Services to help your business grow.</span>
        </li>
        <li className={styles.action}>
          <NavHashLink
            smooth
            to={`/#${linkToElementId}`}
            scroll={(el) => scrollWithOffset(el, 80)}
            className={styles.custombutton}
            onClick={handleClick('about')}
          >
            Learn More
          </NavHashLink>
        </li>
      </ul>
    </div>
  );
};
