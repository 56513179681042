import React from 'react';
import styles from './Feature.module.css';

interface Props {
  imageUrl?: string;
  imageAltText?: string;
  title: string;
  children: React.ReactNode;
}

export const Feature = ({ imageUrl, imageAltText, title, children }: Props) => (
  <div className={styles.feature}>
    <img className={styles.featureImage} src={imageUrl} alt={imageAltText} />
    <h4 className={styles.featureTitle}>{title}</h4>
    <p className={styles.featureBody}>{children}</p>
  </div>
);
