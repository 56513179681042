import React from 'react';
import styles from './OurServices.module.css';
import { GetOurServices, OurServicesData } from './OurServicesData';

export const OurServices = () => {
  const [ourServices, setOurServices] = React.useState<
    OurServicesData[] | null
  >(null);
  React.useEffect(() => {
    setOurServices(GetOurServices());
  }, []);

  return (
    <div className={styles.servicelist}>
      {ourServices &&
        ourServices.map((service, index) => (
          <div key={`service${index}`} className={styles.areas}>
            <h3>{service.title}</h3>
            <img src={service.imageUrl} alt={service.imageAltText} />
            <p>What we offer</p>
            <ul>
              {service.serviceareas.map((area, areaindex) => (
                <li key={`servicearea${areaindex}`}>{area}</li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};
