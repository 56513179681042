import { Store, createStore, combineReducers } from 'redux';

interface NavigationState {
  readonly selected: string;
  readonly loading: boolean;
}

export interface AppState {
  readonly navigationMenu: NavigationState;
}

const initialNavigationState: NavigationState = {
  selected: 'home',
  loading: false
};

// Add 2 Actions to change the current navigation state. used for current state request & responses respectively
export const CHANGINGSELECTEDNAVIGATION = 'ChangingSelectedNavigation';

export const changingSelectedNavigationAction = () =>
  ({ type: CHANGINGSELECTEDNAVIGATION } as const);

export const CHANGEDSELECTEDNAVIGATION = 'ChangedSelectedNavigation';

export const changedSelectedNavigationAction = (selected: string) =>
  ({
    type: CHANGEDSELECTEDNAVIGATION,
    selected: selected
  } as const);

//REDUCERS
type NavigationActions =
  | ReturnType<typeof changingSelectedNavigationAction>
  | ReturnType<typeof changedSelectedNavigationAction>;

const navigationReducer = (
  state = initialNavigationState,
  action: NavigationActions
) => {
  switch (action.type) {
    case CHANGINGSELECTEDNAVIGATION: {
      return {
        ...state,
        selected: '',
        loading: true
      };
    }
    case CHANGEDSELECTEDNAVIGATION: {
      return {
        ...state,
        selected: action.selected,
        loading: false
      };
    }
  }
  return state;
};

const rootReducer = combineReducers<AppState>({
  navigationMenu: navigationReducer
});

export function configureStore(): Store<AppState> {
  const store = createStore(rootReducer, undefined);
  return store;
}
