import React from 'react';
import styles from './PageSection.module.css';
import { PageSectionTitle } from './PageSectionTitle';
//https://github.com/thebuilder/react-intersection-observer/
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import {
  changedSelectedNavigationAction,
  changingSelectedNavigationAction
} from '../../Store';

interface Props {
  title: string;
  caption?: string;
  inverse?: boolean;
  children: React.ReactNode;
  id?: string;
}

export const PageSection = ({
  title,
  caption,
  inverse = false,
  children,
  id
}: Props) => {
  const dispatch = useDispatch();
  const [ref, inView] = useInView({
    threshold: 0.5
  });

  React.useEffect(() => {
    const doChangeNavigationLocation = () => {
      if (id) {
        dispatch(changingSelectedNavigationAction());
        dispatch(changedSelectedNavigationAction(id));
      }
    };

    inView && doChangeNavigationLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div
      ref={ref}
      id={id}
      className={`${styles.container} ${
        inverse ? styles.inverseBackground : styles.normalBackground
      }`}
    >
      <PageSectionTitle caption={caption}>{title}</PageSectionTitle>
      {children}
    </div>
  );
};
