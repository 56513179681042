import React from 'react';
import styles from './About.module.css';
import { Feature } from './Feature';
import { Fade } from 'react-awesome-reveal';
import { FeatureData, GetAboutFeatures } from './FeatureData';

export const About = () => {
  const [aboutFeatures, setAboutFeatures] = React.useState<
    FeatureData[] | null
  >(null);
  React.useEffect(() => {
    setAboutFeatures(GetAboutFeatures());
  }, []);

  return (
    <div className={styles.container}>
      <Fade cascade={true} delay={0.5} triggerOnce={true}>
        <h3 className={styles.bodyText}>
          <span className={styles.blue}>ResonateIT</span> is an Agile software
          consultancy specialising in bespoke application development and
          architecture.
          <br />
          We are passionate about helping our clients reach their full
          potential, and with <span className={styles.blue}>
            competencies
          </span>{' '}
          in .Net, ReactJS, Angular, Azure, DevOps and others, we are{' '}
          <span className={styles.blue}>confident</span> that we can deliver
          quality, scalable, resilient solutions to meet your business needs.
        </h3>
      </Fade>
      <Fade delay={200} duration={1500} triggerOnce={true}>
        <div className={styles.whatwedo}>
          {aboutFeatures &&
            aboutFeatures.map((feature, index) => (
              <Feature
                key={`feature${index}`}
                imageUrl={process.env.PUBLIC_URL + feature.imageUrl}
                imageAltText={feature.imageAltText}
                title={feature.title}
              >
                {feature.content}
              </Feature>
            ))}
        </div>
      </Fade>
    </div>
  );
};
