import React from 'react';
import styles from './NavBar.module.css';
import { Link } from 'react-router-dom';
import { CompanyLogo } from '../../Icons';
import { NavHashLink } from 'react-router-hash-link';
import { useSelector, useDispatch } from 'react-redux';
import { MobileNav } from './MobileNav';
import {
  AppState,
  changedSelectedNavigationAction,
  changingSelectedNavigationAction
} from '../../Store';

interface Props {
  showPortfolio?: boolean;
  showBlog?: boolean;
}

export const NavBar = ({ showPortfolio = false, showBlog = false }: Props) => {
  const dispatch = useDispatch();
  const activeNavItem = useSelector(
    (state: AppState) => state.navigationMenu.selected
  );

  const scrollWithOffset = (el: HTMLElement, offset: number) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleClick = (value: string) => () => {
    dispatch(changingSelectedNavigationAction());
    dispatch(changedSelectedNavigationAction(value));
    console.log('aa');
  };

  return (
    <div className={styles.navbar}>
      <Link to="/" className={styles.logo}>
        <CompanyLogo />
      </Link>
      <ul className={styles.navLinks}>
        <li>
          <NavHashLink
            smooth
            to="/#home"
            className={`${styles.navBarButton} ${
              activeNavItem === 'home' ? styles.active : ''
            }`}
            onClick={handleClick('home')}
            replace={true}
          >
            Home
          </NavHashLink>
        </li>
        <li>
          <NavHashLink
            smooth
            to="/#about"
            className={`${styles.navBarButton} ${
              activeNavItem === 'about' ? styles.active : ''
            }`}
            scroll={(el) => scrollWithOffset(el, 80)}
            onClick={handleClick('about')}
            replace={true}
          >
            About
          </NavHashLink>
        </li>
        <li>
          <NavHashLink
            smooth
            to="/#services"
            className={`${styles.navBarButton} ${
              activeNavItem === 'services' ? styles.active : ''
            }`}
            scroll={(el) => scrollWithOffset(el, 80)}
            onClick={handleClick('services')}
            replace={true}
          >
            Services
          </NavHashLink>
        </li>
        {showPortfolio && (
          <li>
            <Link to={'/'}>Portfolio</Link>
          </li>
        )}
        <li>
          <NavHashLink
            smooth
            to="/#contact"
            className={`${styles.navBarButton} ${
              activeNavItem === 'contact' ? styles.active : ''
            }`}
            scroll={(el) => scrollWithOffset(el, 80)}
            onClick={handleClick('contact')}
            replace={true}
          >
            Contact
          </NavHashLink>
        </li>
        {showBlog && (
          <li>
            <Link to={'/'}>Blog</Link>
          </li>
        )}
      </ul>
      <div className={styles.toggleNav}>
        <MobileNav />
      </div>
      {/* <button className={styles.toggleNav}>&#9776;</button> */}
    </div>
  );
};
