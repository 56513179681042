import React from 'react';
import styles from './Contact.module.css';
// import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ContactData, PostContact } from './ContactData';
import emailImage from '../assets/email.svg';
import { Fade, Rotate } from 'react-awesome-reveal';
export const Contact = () => {
  // React.useEffect(() => {
  //   const doGetContact = async () => {
  //     await PingContact();
  //   };
  //   doGetContact();
  // }, []);
  const { register, handleSubmit, reset, formState } = useForm<ContactData>({
    mode: 'onBlur'
  });
  const [successfullySubmitted, setSuccessfullySubmitted] =
    React.useState(false);

  //const [searchParams] = useSearchParams();
  //   const nameParam = searchParams.get('name') || '';
  //   const emailParam = searchParams.get('email') || '';
  //   const messageParam = searchParams.get('message') || '';

  const submitContactForm = async (data: ContactData) => {
    const result = await PostContact({
      name: data.name,
      email: data.email,
      message: data.message
    });

    setSuccessfullySubmitted(result ? true : false);
    console.log(`${data.name} - ${data.email} - ${data.message}`);
    reset();
  };

  return (
    <div className={styles.contact}>
      {!successfullySubmitted ? (
        <>
          <Fade delay={100} duration={1000} direction="left" triggerOnce={true}>
            <div className={styles.formWrapper}>
              <form
                id="feedbackForm"
                onSubmit={handleSubmit(submitContactForm)}
              >
                <div>
                  <label htmlFor="name">Name *</label>
                  <div>
                    <input
                      {...register('name', { required: true })}
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter your name"
                      disabled={formState.isSubmitting}
                    />
                  </div>
                  {formState.errors &&
                    formState.errors.name?.type === 'required' && (
                      <span className={styles.error}>
                        Please enter your name.
                      </span>
                    )}
                </div>

                <div>
                  <label htmlFor="email">Email Address *</label>
                  <div>
                    <input
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Please enter a valid email address'
                        }
                      })}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      disabled={formState.isSubmitting}
                    />
                  </div>
                  {formState.errors && formState.errors.email && (
                    <span className={styles.error}>
                      Please enter a valid email address.
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="message">Message *</label>
                  <div>
                    <textarea
                      {...register('message', { required: true })}
                      id="message"
                      name="message"
                      placeholder="Enter your message"
                      disabled={formState.isSubmitting || successfullySubmitted}
                    ></textarea>
                  </div>
                  {formState.errors &&
                    formState.errors.message?.type === 'required' && (
                      <span className={styles.error}>
                        Please enter a message.
                      </span>
                    )}
                </div>
                <button
                  type="submit"
                  id="feedbackSubmit"
                  className={styles.custombutton}
                >
                  Send
                </button>
              </form>
            </div>
          </Fade>

          <Fade
            delay={100}
            duration={1000}
            direction="right"
            triggerOnce={true}
          >
            <div className={styles.infoWrapper}>
              <h4>Drop us a line!</h4>
              <p className={styles.informationText}>
                Get in touch to find out how we can help you.
                <br />
                We would love to hear from you and will do our best to reply as
                fast as we can
              </p>
              <h4>Our Email!</h4>
              <p>
                <a href="./" className={styles.emailLink}>
                  info@resonateit.co.uk
                </a>
              </p>
            </div>
          </Fade>
        </>
      ) : (
        <div className={styles.emailWrapper}>
          <Rotate duration={1500} triggerOnce={true}>
            <img
              src={emailImage}
              alt="email logo"
              className={styles.emaillogo}
            />
          </Rotate>
          <h3>
            <span className={styles.blue}>Thank you</span> for your message.
            <br />
            We look forward to speaking with you and will be contacting you very
            soon.
          </h3>
        </div>
      )}
    </div>
  );
};
