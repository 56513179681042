export const server =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://resonate-api.azurewebsites.net'
    : process.env.REACT_APP_ENV === 'staging'
    ? 'https://resonate-api-staging.azurewebsites.net'
    : 'http://localhost:5001';

export const webAPIUrl = `${server}/api`;

export const authSettings = {
  authority:
    'https://login.microsoftonline.com/1116cba3-e6b8-45c6-a6f8-a1478ffd2e5a',
  client_id: 'bc0958ec-0d93-4c97-9d8b-cd846fa4eb5f',
  redirect_uri: window.location.origin,
  logoutRedirectUri: window.location.origin
};
