import React from 'react';
import { AttentionSeeker } from 'react-awesome-reveal';
import styles from './PageSectionTitle.module.css';

interface Props {
  caption?: string;
  children: React.ReactNode;
}

export const PageSectionTitle = ({ caption, children }: Props) => (
  <div className={styles.sectionTitleContainer}>
    <AttentionSeeker effect="pulse" triggerOnce={true}>
      <h2 className={styles.headerText}>{children}</h2>
    </AttentionSeeker>

    {caption && (
      <>
        <span className={styles.center_border_line}></span>
        <hr className={styles.divider} />
        <p className={styles.sub_text}>{caption}</p>
      </>
    )}
  </div>
);
