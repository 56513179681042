import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Footer.module.css';

export const Footer = () => {
  const getDate = () => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  };

  return (
    <>
      <div className={styles.company}>
        <ul>
          <li>
            <span className={styles.blue}>ResonateIT</span>
          </li>
          <li>Company Reg. No. 13758625</li>
          <li>
            <NavLink to="/admin">Sign In</NavLink>
          </li>
        </ul>
        <ul>
          <li>
            <span className={styles.blue}>Registered Address</span>
          </li>
          <li>27 Old Gloucester Street, London, </li>
          <li>WC1N 3AX, UK</li>
        </ul>
        <ul>
          <li>
            <span className={styles.blue}>Contact Us</span>
          </li>
          <li>info@resonateit.co.uk</li>
          <li>&nbsp;</li>
        </ul>
      </div>
      <div className={styles.copyright}>
        © {getDate()} ResonateIT. Website Developed by C Chidi using React
      </div>
    </>
  );
};
