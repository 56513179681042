import * as React from 'react';
import styles from './MobileNav.module.css';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { HashLink } from 'react-router-hash-link';

interface Props {
  showPortfolio?: boolean;
  showBlog?: boolean;
}

export const MobileNav = ({
  showPortfolio = false,
  showBlog = false
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const scrollWithOffset = (el: HTMLElement, offset: number) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <span className={styles.navButton}>&#9776;</span>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleClose}>
                      <HashLink
                        smooth
                        to="/#home"
                        className={styles.navBarButton}
                        scroll={(el) => scrollWithOffset(el, 80)}
                        replace={true}
                      >
                        Home
                      </HashLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <HashLink
                        smooth
                        to="/#about"
                        className={styles.navBarButton}
                        scroll={(el) => scrollWithOffset(el, 80)}
                        replace={true}
                      >
                        About
                      </HashLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <HashLink
                        smooth
                        to="/#services"
                        className={styles.navBarButton}
                        scroll={(el) => scrollWithOffset(el, 80)}
                        replace={true}
                      >
                        Services
                      </HashLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <HashLink
                        smooth
                        to="/#contact"
                        className={styles.navBarButton}
                        scroll={(el) => scrollWithOffset(el, 80)}
                        replace={true}
                      >
                        Contact
                      </HashLink>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
};
