export interface OurServicesData {
  imageUrl?: string;
  imageAltText?: string;
  title: string;
  serviceareas: string[];
}

export const ourServicesData: OurServicesData[] = [
  {
    imageUrl: 'images/development.svg',
    imageAltText: '',
    title: 'Development',
    serviceareas: [
      'C# / ASP.NET / .NET Core',
      'Angular',
      'React / Redux / TypeScript',
      'HTML5 / CSS3',
      'Xamarin (Android / IOS)',
      'Azure Cloud / Serverless',
      'RESTful / Web Services',
      'Node.js / JQuery / Javascript'
    ]
  },
  {
    imageUrl: 'images/architecture.svg',
    imageAltText: '',
    title: 'Architecture',
    serviceareas: [
      'Event Driven Architecture',
      'MicroServices',
      'Serverless',
      'Domain Driven Design',
      'Application Architecture',
      'Solution Architecture',
      'CQRS / Event Sourcing',
      'Azure Cloud'
    ]
  },
  {
    imageUrl: 'images/devops.svg',
    imageAltText: '',
    title: 'DevOps',
    serviceareas: [
      'Agile/Scrum',
      'Source Code Management',
      'CI/CD',
      'IaC/IoD',
      'Continuous Testing',
      'Package Management',
      'Configuration Management',
      'Monitoring and Alerting'
    ]
  }
];

export const GetOurServices = (): OurServicesData[] => {
  return ourServicesData;
};
