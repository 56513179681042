export interface FeatureData {
  imageUrl: string;
  imageAltText?: string;
  title: string;
  content: string;
}

export const aboutFeatures: FeatureData[] = [
  {
    imageUrl: 'images/development.svg',
    imageAltText: '',
    title: 'Development',
    content:
      'We build and enhance  applications to support your various business processes'
  },
  {
    imageUrl: 'images/architecture.svg',
    imageAltText: '',
    title: 'Architecture',
    content:
      'We design tech solutions to meet your requirements and address your business problems'
  },
  {
    imageUrl: 'images/devops.svg',
    imageAltText: '',
    title: 'DevOps',
    content:
      'We implement key DevOps practices and technologies to enable continuous delivery of value to your end users'
  }
];

export const GetAboutFeatures = (): FeatureData[] => {
  return aboutFeatures;
};
